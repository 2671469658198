import OutputHelper from './OutputHelper';

export default {
  // Stauchung
  getCompression(t) {
    const tCasted = OutputHelper.convertToInteger(t);
    return 0.5 * tCasted + 1;
  },
  // Mindestabstand zu den verformten Bauteilen
  getMinDistance(t, compression, edgeDistance, alphaTotalB) {
    const tCasted = OutputHelper.convertToInteger(t);
    const compressionCasted = OutputHelper.convertToNumber(compression);
    const edgeDistanceCasted = OutputHelper.convertToNumber(edgeDistance);
    const alphaTotalBCasted = OutputHelper.convertToNumber(alphaTotalB);
    return tCasted - compressionCasted - edgeDistanceCasted * alphaTotalBCasted;
  },
};
