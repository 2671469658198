<template>
  <div class="row">
    <div class="col-lg-6">
      <div class="panel has-bg">
        <h3>{{ $t('bearings.headlineBearingDimension') }}</h3>
        <div class="row">
          <!-- Lagerbeite -->
          <div class="col-lg-4">
            <b-form-group
              label-for="bearingWidth"
            >
              <template slot="label">
                {{ $t('bearings.bearingWidth') }} <span class="text-muted">a</span>
              </template>
              <b-input-group append="mm">
                <FormInputInteger
                  id="bearingWidth"
                  v-model="bearingWidth"
                />
              </b-input-group>
            </b-form-group>
          </div>
          <!-- Lagerlänge -->
          <div class="col-lg-4">
            <b-form-group
              label-for="bearingLength"
            >
              <template slot="label">
                {{ $t('bearings.bearingLength') }} <span class="text-muted">b</span>
              </template>
              <b-input-group append="mm">
                <FormInputInteger
                  id="bearingLength"
                  v-model="bearingLength"
                />
              </b-input-group>
            </b-form-group>
          </div>
          <!-- Lagerdicke -->
          <div class="col-lg-4">
            <b-form-group
              label-for="bearingThickness"
            >
              <template slot="label">
                {{ $t('bearings.bearingThickness') }} <span class="text-muted">t</span>
              </template>
              <b-input-group>
                <b-form-select
                  id="bearingThickness"
                  v-model="bearingThickness"
                  :options="bearingThicknessOptions"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <!-- Randabstand -->
          <div class="col-lg-4">
            <b-form-group
              label-for="edgeDistance"
            >
              <template slot="label">
                {{ $t('edgeContact.edgeDistance') }}
              </template>
              <b-input-group append="mm">
                <FormInputInteger
                  id="edgeDistance"
                  v-model="edgeDistance"
                  type="text"
                >
                </FormInputInteger>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="panel has-bg">
        <h3>{{ $t('bearings.headlineEffects') }}</h3>
        <div class="row">
          <!-- Kontaktfläche -->
          <div class="col-sm-6">
            <b-form-group
              label-for="typeContactSurface"
            >
              <template slot="label">
                {{ $t('bearings.typeContactSurface') }}
              </template>
              <b-input-group>
                <b-form-select
                  id="typeContactSurface"
                  v-model="typeContactSurface"
                  :options="typeContactSurfaceOptions"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <!-- Winkelverdrehung a -->
          <div class="col-lg-6">
            <b-form-group
              label-for="radTwistingA"
            >
              <template slot="label">
                {{ $t('eccentricity.radTwisting') }}
                <span class="text-muted">a <sub class="text-muted">a, d</sub></span>
                <b-button
                  id="popover-rad-twisting-a"
                  variant="default"
                >
                  <font-awesome-icon icon="question-circle" />
                </b-button>
                <b-popover
                  target="popover-rad-twisting-a"
                  triggers="hover"
                  placement="top"
                >
                  <p>{{ $t('eccentricity.radTwistingATooltip') }}</p>
                  <p v-html="$t('general.tooltipAdditionalInfo')"></p>
                </b-popover>
              </template>
              <b-input-group append="rad">
                <FormInputDecimal
                  id="radTwistingA"
                  v-model="twistingA"
                  decimal-places="3"
                />
              </b-input-group>
            </b-form-group>
          </div>
          <!-- Winkelverdrehung b -->
          <div class="col-lg-6">
            <b-form-group
              label-for="radTwistingB"
            >
              <template slot="label">
                {{ $t('eccentricity.radTwisting') }}
                <span class="text-muted">a <sub>b, d</sub></span>
                <b-button
                  id="popover-rad-twisting-b"
                  variant="default"
                >
                  <font-awesome-icon icon="question-circle" />
                </b-button>
                <b-popover
                  target="popover-rad-twisting-b"
                  triggers="hover"
                  placement="top"
                >
                  <p>{{ $t('eccentricity.radTwistingBTooltip') }}</p>
                  <p v-html="$t('general.tooltipAdditionalInfo')"></p>
                </b-popover>
              </template>
              <b-input-group append="rad">
                <FormInputDecimal
                  id="radTwistingB"
                  v-model="twistingB"
                  decimal-places="3"
                />
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <!-- Checkbox Verdrehungszuschlag -->
        <div>
          <b-form-checkbox
            id="withTwistSurcharge"
            v-model="twistingWithSurcharge"
            name="withTwistSurcharge"
          >
            {{ $t('bearings.withTwistSurcharge') }}
            <b-button
              id="popover-twist-surcharge"
              variant="default"
            >
              <font-awesome-icon icon="question-circle" />
            </b-button>
            <b-popover
              target="popover-twist-surcharge"
              triggers="hover"
              placement="top"
            >
              <p>
                {{ $t('bearings.withTwistSurchargeTooltip1') }}
                <strong>
                  {{ $t('bearings.withTwistSurchargeTooltip2') }}
                </strong>
              </p>
              <p v-html="$t('general.tooltipAdditionalInfo')"></p>
            </b-popover>
          </b-form-checkbox>
        </div>
        <!-- Verdrehungszuschlag -->
        <div
          v-if="twistingWithSurcharge"
          class="mt-2"
        >
          <div class="row">
            <!-- Verdrehungszuschlag Schiefwinklig -->
            <div class="col-md-6">
              {{ $t('bearings.twistingSurcharge') }}
              <span class="text-muted">
                &alpha; <sub>{{ $t('bearings.twistingSurchargeAskew') }} </sub>
              </span>
              <span class="text-muted">
                {{ twistingSurchargeAskew | numeralFormat('0.0') }} &permil;
              </span>
            </div>
            <!-- Verdrehungszuschlag Unebenheit -->
            <div class="col-md-6">
              {{ $t('bearings.twistingSurcharge') }}
              <span class="text-muted">
                &alpha; <sub>{{ $t('bearings.twistingSurchargeRoughness') }} </sub>
              </span>
              <span class="text-muted">
                {{ twistingSurchargeRoughness | numeralFormat('0.0') }} &permil;
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <h3>
        {{ $t('bearings.headlineKeyFigures') }}
        <b-button
          id="popover-rad-twisting-total-a"
          variant="link"
        >
          <font-awesome-icon icon="question-circle" />
        </b-button>
        <b-popover
          target="popover-rad-twisting-total-a"
          triggers="hover"
          placement="top"
          container="body"
        >
          <p>{{ $t('edgeContact.edgeContactTooltip') }}</p>
          <p v-html="$t('general.tooltipAdditionalInfo')"></p>
        </b-popover>
      </h3>
      <div class="table-responsive">
        <table class="table table-striped">
          <tbody>
            <tr>
              <td>
                {{ $t('edgeContact.compression') }}
              </td>
              <td class="text-right">
                <span class="text-muted">{{ compression | numeralFormat('0') }} mm</span>
              </td>
            </tr>
            <tr :class="minDistanceStatus">
              <td>
                {{ $t('edgeContact.minDistance') }} <span class="text-muted">K<sub>a</sub></span>
              </td>
              <td class="text-right">
                <strong>
                  {{ minDistance | numeralFormat('0.0') }} mm
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="panel has-bg">
        <h3>{{ $t('edgeContact.headlineIllustration') }}</h3>
        <img
          src="../../assets/img/abbildungen/kantenkontakt.jpg"
          :alt="$t('edgeContact.headlineIllustration')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormInputInteger from '../app/form/FormInputInteger.vue';
import FormInputDecimal from '../app/form/FormInputDecimal.vue';
import DisabledInputs from '../../mixins/DisabledInputs';
import CalcToolsEdgeContact from '../../helpers/CalcToolsEdgeContactHelper';

export default {
  components: {
    FormInputInteger,
    FormInputDecimal,
  },
  mixins: [DisabledInputs],
  props: {
    propBearingWidth: {
      type: Number,
      default: 100,
    },
    propBearingLength: {
      type: Number,
      default: 150,
    },
    propBearingThickness: {
      type: Number,
      default: 20,
    },
    propTwistingA: {
      type: Number,
      default: 0,
    },
    propTwistingB: {
      type: Number,
      default: 0,
    },
    propTwistingWithSurcharge: {
      type: Boolean,
      default: false,
    },
    propTypeContactSurface: {
      type: String,
      default: 'steel',
    },
  },
  data() {
    return {
      bearingThicknessOptions: Object.values(this.$dataOptionsHelper.getBearingThicknessOptions()),
      typeContactSurfaceOptions: Object.values(
        this.$dataOptionsHelper.getTypeContactSurfaceOptions(this.$i18n),
      ),
      edgeDistance: 20,
      twistingSurchargeAskew: 10,
      bearingWidth: this.propBearingWidth,
      bearingLength: this.propBearingLength,
      bearingThickness: this.propBearingThickness,
      twistingA: this.propTwistingA,
      twistingB: this.propTwistingB,
      twistingWithSurcharge: this.propTwistingWithSurcharge,
      typeContactSurface: this.propTypeContactSurface,
    };
  },
  computed: {
    compression() {
      return CalcToolsEdgeContact.getCompression(this.bearingThickness);
    },
    minDistance() {
      return CalcToolsEdgeContact.getMinDistance(
        this.bearingThickness,
        this.compression,
        this.edgeDistance,
        this.twistingTotal.totalB / 1000,
      );
    },
    minDistanceStatus() {
      return {
        'table-success text-success': this.minDistance >= 3.0,
        'table-danger text-danger': this.minDistance < 3.0,
      };
    },
    twistingSurchargeRoughness() {
      const mainStressedSide = this.$calcHelper.getMainStressedSide(
        this.$outputHelper.convertToNumber(this.twistingA) * 1000,
        this.$outputHelper.convertToNumber(this.twistingB) * 1000,
        this.bearingWidth,
        this.bearingLength,
        this.bearingThickness,
      );
      return this.$calcHelper.getSurchargeRoughness(mainStressedSide, this.typeContactSurface);
    },
    twistingTotal() {
      return this.$calcHelper.getTwisting(
        this.$outputHelper.convertToNumber(this.twistingA) * 1000,
        this.$outputHelper.convertToNumber(this.twistingB) * 1000,
        this.bearingWidth,
        this.bearingLength,
        this.twistingWithSurcharge,
        this.typeContactSurface,
      );
    },
  },
};
</script>

<style scoped lang="scss">

</style>
